<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
    :overlay="false"
    scrollable>
    <v-card>
      <v-toolbar class="grey lighten-3">
        <v-toolbar-title>
          Invia ({{ ddts.length }}) DDT via mail
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$router.push({ name: 'ddts'})"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-layout>
            <v-flex xs12 md6>
              <p class="mb-5"><strong>DDT allegati:</strong><span :key="d._id" v-for="d in ddts"> {{d.progressivo}}-{{d.esercizio}}</span></p>
              <v-form ref="formMail" v-model="validFormMail">
                <v-text-field
                  label="Destinatario"
                  v-model="ddt.to"
                  prepend-icon="contacts"
                  :rules="[$rules.email, $rules.required]" />
                <v-combobox
                  label="cc"
                  multiple
                  small-chips
                  cache-items
                  clearable
                  deletable-chips
                  v-model="ddt.cc"
                  prepend-icon="contacts"
                  :rules="[$rules.isArrayOfEmails]" />
                <v-combobox
                  label="ccn"
                  multiple
                  small-chips
                  cache-items
                  clearable
                  deletable-chips
                  v-model="ddt.ccn"
                  prepend-icon="contacts"
                  :rules="[$rules.isArrayOfEmails]" />
                <v-text-field
                  :disabled="ddt._id != null"
                  label="Oggetto"
                  v-model="ddt.subject"
                  prepend-icon="edit"
                  :rules="[$rules.required]" />
                <v-textarea
                  auto-grow
                  label="Messaggio"
                  v-model="ddt.message"
                  :rules="[$rules.maxlength(4000), $rules.required]" />
              </v-form>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions class="ml-4">
          <v-btn
            color="primary"
            :disabled="!validFormMail"
            @click.native="send()">
            Invia
          </v-btn>
          <v-btn @click="$router.push({ name: 'ddts' })">Annulla</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from '@/eventBus'
import { ddtMixin } from '@/components/produzione/ddt/ddtMixin'

export default {
  mixins: [ddtMixin],
  data: () => ({
    dialog: false,
    validFormMail: false,
    ddts: [],
    ddt: {}
  }),
  methods: {
    send () {
      this.$http({
        method: 'post',
        url: `/mail/ddt/mailzip`,
        // data: { completeMessage: { mailSubject: 'Attenzione DDT pronto' }, dtts:this.selectedDdts }
        data: { mail: this.ddt, ddts: this.ddts }
      }).then(() => {
        $EventBus.$emit('message', { type: 'success', text: 'Mail inviata!' })
        this.$router.push({ name: 'ddts' })
      }).catch((err) => {
        console.log(err)
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile inviare la mail' })
      })
    }
  },
  mounted () {
    let processDdtForMail = this.processDdtForMail
    this.$plsqlMethod('ddtp', 'get_ddt_for_mail', { ddts: this.$route.query.ddts })
      .then(response => {
        response.data.forEach(function (ddt) {
          ddt = processDdtForMail(ddt)
        })
        this.ddts = response.data
        this.dialog = true
        if (this.ddts.length) {
          this.ddt.message = `Spett. ${this.ddts[0].ragione_sociale_cliente},\n\nin allegato i documenti di trasporto della merce.\n\nCordiali saluti.\nCon-Plast`
          this.ddt.to = this.ddts[0].mail_ddt
        } else $EventBus.$emit('message', { type: 'error', text: 'Nessun DDT selezionato' })
      })
      .catch(err => {
        console.log('errore client', err)
        $EventBus.$emit('message', { type: 'error', text: 'Errore' })
      })
  }
}
</script>
